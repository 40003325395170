import { useSelectedStoreNumber } from '@cfacorp/ctrl-platform-shared-react-components';
import { useEffect } from 'react';

/** Selected store number. */
export let selectedStoreNumber = '';

export const useStoreNumberUpdater = () => {
  const number = useSelectedStoreNumber();

  useEffect(() => {
    selectedStoreNumber = number;
  }, [number]);
};
