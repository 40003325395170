import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import Providers from './Providers';

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: () => (
    <Providers>
      <Root />
    </Providers>
  ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <></>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
