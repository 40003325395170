import { useEffect, useState } from 'react';
import { useUserPermissions } from '@cfacorp/ctrl-platform-shared-react-components';

const useHasTimePunchPerms = () => {
  const cfaPerms = useUserPermissions();
  const [hasTimePunchPerms, setHasTimePunchPerms] = useState(true);
  const [permsLoaded, setPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAuthorized =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.TIMEPUNCH_USER?.length ||
        !!cfaPerms.SP_CONFIG?.TIMEPUNCH_ADMIN?.length;
      setHasTimePunchPerms(isAuthorized);
      setPermsLoaded(true);
    }
  }, [cfaPerms]);

  return { hasTimePunchPerms, permsLoaded };
};

export default useHasTimePunchPerms;
