/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  AppRoutes: {
    mainMenu: 'Main Menu',
  },
  Home: {
    areYouSure: 'Are you sure',
    breakNotificationMaximum: 'Break Notification Maximum',
    breakNotificationMinimum: 'Break Notification Minimum',
    cancel: 'Cancel',
    cardSubtitle: 'Subtitle',
    cardTitle: 'Card Title',
    category: 'Category',
    createGroup: 'Create Group',
    dangerZone: 'Danger Zone',
    default: 'Default',
    delete: 'Delete',
    edit: 'Edit',
    editing: 'Editing',
    groupCreated: 'Group Created',
    groupNameRequired: 'Group Name is required',
    groups: 'Groups',
    longBreakDesc: 'Long Break Requires Mgr Approval',
    nameUpdated: 'Name has been updated.',
    paidBreak: 'Paid Break',
    save: 'Save',
    shortBreakDesc: 'Short Break Requires Mgr Approval',
    title: 'Time Punch Clock Settings',
    unpaidBreak: 'Unpaid Break',
    unsavedChanges: 'Unsaved Changes',
    unsavedChangesMessage:
      'You have unsaved changes still, please save or cancel.',
    updated: 'updated',
    updateGroupName: 'Update Group Name',
  },
};
