import { lazy, Suspense, useEffect, useMemo } from 'react';
import './i18n';
import {
  appLoadingStatuses,
  globalRoutes,
  userPermissions,
} from '@cfacorp/ctrl-platform-ui-core-utils';
import RouteInfo from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/RouteInfo';
import useHasTimePunchPerms from './hooks/useHasTimePunchPerms';
import RouteConstants from './routes/RouteConstants';
import { useSelectedStoreNumber } from '@cfacorp/ctrl-platform-shared-react-components';

const AppRouter = lazy(() => import('./routes/AppRouter'));

export default function Root() {
  const routes: RouteInfo[] = useMemo(
    () => [
      {
        route: `${RouteConstants.BASE_NAME}`,
        displayName: 'Time Punch Clock Home',
        keywords: ['Time Punch Clock'],
      },
    ],
    [],
  );
  const { hasTimePunchPerms, permsLoaded } = useHasTimePunchPerms();
  const selectedStoreNumber = useSelectedStoreNumber();

  useEffect(() => {
    if (permsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasTimePunchAccess: hasTimePunchPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        timePunchLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...routes],
      });
    }
  }, [hasTimePunchPerms, permsLoaded, routes]);

  return (
    <Suspense fallback={null}>{selectedStoreNumber && <AppRouter />}</Suspense>
  );
}
