import { queryClient } from '@/utils/react-query';
import { ThemeProvider } from '@cfa/react-core/theme';
import { QueryClientProvider } from '@tanstack/react-query';
import { FC, PropsWithChildren } from 'react';
import './global.css';
import { useStoreNumberUpdater } from '@/utils/store-number';
import { ToastContainer } from 'react-toastify';

const Providers: FC<PropsWithChildren> = ({ children }) => {
  useStoreNumberUpdater();

  return (
    <ThemeProvider>
      <ToastContainer position="bottom-center" />
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ThemeProvider>
  );
};

export default Providers;
